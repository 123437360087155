body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
    margin: 1em;
}

h1.title {
    margin-bottom: -5px;
}

.version-label {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 8px;
    margin-left: 5px;
}

.truck-scheduler-app {
    margin-top: -20px;
}

.draggable {
    background-color: rgb(194, 194, 194);
    margin: 5px;
    padding: 10px;
    width: 4em;
    text-align: center;
}

.draggable.block, .draggable.yard {
    width: 5em;
}

.draggable:hover {
    background-color: rgba(224, 224, 151, 0.952);
    cursor: grab;
}

.drag-disabled {
    -webkit-user-select: none;
            user-select: none;
}

.drag-disabled:hover {
    cursor: pointer;
}

.scheduler-canvas {
    position: relative;
    height: 100%;
}

.scheduler-canvas-body {
    position: relative;
    background-color: rgb(245, 245, 245);
    min-height: 580px;
    width: 2298px;
}

.scheduler-canvas-container .schedule-rows .operating-unit-name-label {
    position: relative;
    color: gray;
}

.location-draggable-section {
    display: flex;
    margin-left: 15px;
}

.location-draggable-list {
    display: flex;
    flex-wrap: wrap;
    width: 13em;
    height: 0px;
}

.bottom-panel {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    left: 20px;
    width: 0px;
}

.dashboard {
    position: absolute;
    left: 1030px;
    width: 450px;
}

.dashboard-block .loader-operator-list {
    margin-top: 5px;
    margin-left: -20px;
}

.draggable-sections, .schedule-row, .location-section {
    display: flex;
}

.draggable-sections {
    margin-top: 10px;
}

.truck-draggable-list .draggable {
    margin-bottom: 10px;
}

.truck-draggable-list, .destination-draggable-list, .block-draggable-list, .yard-draggable-list {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
}

.truck-draggable-list {
    width: 13em;
}

.block-draggable-list {
    width: 30em;
}

.block-draggable-list {
    height: 15em;
}

.yard-draggable-list {
    width: 8em;
    flex-direction: column;
    flex-wrap: nowrap;
}

.schedule-row {
    margin-bottom: 15px;
    margin-top: 10px;
    font-size: 12px;
    position: relative;
}

.schedule-rows {
    padding-top: 1px;
}

.truck-section, .scenario-truck-item {
    position: relative; 
    margin-right: 5px;
    width: 115px;
    height: 33px;
    padding: 10px;
    background: #dce4f2;
    z-index: 1;
    box-sizing: border-box;
    cursor: pointer;
}

.add-truck-prompt {
    position: absolute;
    z-index: 5;
    margin-bottom: 15px;
    margin-right: 5px;
    height: 30px;
    width: 115px;
    background: #c2c2c2;
    padding-left: 45px;
    font-size: 24px;
    box-sizing: border-box;
}

.location-item {
    padding-top: 10px;
    padding-left: 2px;
    height: 33px;
    background: #ffe1b7;
    text-transform: capitalize;
    z-index: 2;
    box-sizing: border-box;
    position: relative;
}

.location-item.block:hover {
    cursor: pointer;
}

.location-item.dryland:hover {
    cursor: pointer;
}

.location-item.transit:hover {
    cursor: pointer;
}

.add-location-prompt {
    margin-left: 15px;
    font-size: 24px;
    width: 40px;
    padding-top: 2px;
    padding-left: 12px;
}

.time-markers {
    display: flex;
    margin-left: 120px;
    margin-bottom: 5px;
    width: 2500px;
}

.time-marker:nth-child(odd) {
    background: #dedede73;
}

.time-marker {
    width: 120px;
    padding: 5px;
    box-sizing: border-box;
}

.current-time-tracker {
    border-left: 2px solid #ff23009c;
    position: absolute;
    height: 100%;
    margin-left: 120px;
    z-index: 3;
}

.dryland {
    background: #cea3a3be;
}

.block {
    background: #bcd192d6;
}

.yard {
    background: #a1d6e2d6;
    text-transform: capitalize;
}

.hour-markers-container {
    margin-left: 120px;
}

.half-hour-marker {
    width: 60px;
    border-right: 0.5px solid #e4e4e4;
    position: absolute;
    height: 100%;
    z-index: 0;
}

.hour-marker {
    margin-left: 60px;
    width: 60px;
    border-right: 1px solid #cecece;
    position: absolute;
    height: 100%;
    z-index: 1;
}

.hour-marker-container:nth-child(odd) {
    position: absolute;
    width: 120px;
    height: 100%;
    background: #fefefed9;
}

.start-time-setter {
    margin-left: 1px;
    z-index: 1;
}

.begin-workday-marker, .end-workday-marker {
    border-left: 2px solid #d23434b8;
    height: 100%;
    position: absolute;
    z-index: 3;
}

.workday-marker-container {
    position: relative;
}

.schedule-background {
    background: #e8e8e800;
    width: 100%;
    border-top: 0.5px solid #dcdcdc;
    border-bottom:  0.5px solid #dcdcdc;
    position: absolute;
    height: 100%;
}

.scheduler-canvas-container {
    max-height: 580px;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 2298px;
}

.delete-item {
    display: none;
    position: absolute;
    background: #b5b5b594;
    width: 25px;
    padding-left: 7px;
    font-size: 14px;
    text-align: left;
    color: #414141;
    top: -12px;
    right: -5px;
    z-index: 2;
    cursor: pointer;
    box-sizing: border-box;
}

.location-item:hover > .delete-item, .scenario-truck-item:hover > .delete-item {
    display: block;
}

.block-id-picker {
    margin-bottom: 10px;
}

.block-load-rows {
    margin-left: -15px;
}

.load-order-line-break {
    border-bottom: 0.5px solid #cacaca;
    width: 415px;
}

.block-load-row
    .number-of-loads,
    .destination-picker,
    .delete-block-load-row {
    margin-right: 20px;
    margin-bottom: 10px;
}

.block-load-row .number-of-loads {
    width: 50px;
    margin-left: 25px;
}

.block-load-rows .assigned-value, .remaining-value  {
    box-sizing: border-box;
    display: inline-block;
    width: 45px;
    text-align: right;
}

.block-load-rows .remaining-value {
    margin-left: 40px;
}

.block-load-row .destination-picker {
    margin-left: 50px;
}

.block-load-edit-labels label {
    margin-right: 20px;
}

.block-load-edit-labels {
    margin-bottom: 10px;
}

.block-load-edit-labels .total-label {
    margin-right: 35px;
}

.block-load-edit-labels .order-label {
    margin-right: 30px;
}

.react-contextmenu {
    background: white;
    border: 0.5px solid #bbbbbb;
    z-index: 10;
}

.react-contextmenu-item {
    padding: 10px;
    border-bottom: 0.5px solid #bbbbbb;
}

.react-contextmenu-item:hover {
    background: #20a0ff;
    cursor: pointer;
    color: white;
}

.react-contextmenu-item--disabled {
    background: white;
    color: #cecece;
    cursor: default;
    border-right: 0.5px solid #bbbbbb;
}

.ReactModal__Overlay {
    z-index: 100;
}

.ReactModal__Content {
    width: 430px;
}

.context-menu-title {
    padding: 5px;
    font-size: 12px;
    border-bottom: 0.5px solid #e4e4e4;
    pointer-events: none;
}

.block-load-editor button.done {
    margin-left: 30px;
}

.block-load-editor .add-load {
    margin-left: 262px;
}

.block-load-editor .block-load-comment {
    resize: none;
    width: 408px;
}

.transit {
    background-color: #8194c5de;
    z-index: 2;
}

.scenario-date-picker {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    padding: 10px;
    padding-top: 0px;
    margin-top: 20px;
    display: flex;
}

.scenario-date-picker input {
    height: 18px;
    width: 150px;
    padding: 5px;
    font-size: 16px;
    font-weight: 400;
}

.scenario-date-picker .DayPickerInput-Overlay {
    z-index: 10;
}

.scenario-date-picker .DayPicker-TodayButton {
    margin-left: 110px;
}

.truck-scheduler-header {
    display: flex;
    margin-bottom: 5px;
}

.truck-scheduler-header .logo {
  width: 152px;
  height: 43.5px;
  margin-top: 24px;
  margin-left: -12px;
}

.scenario-edit-section {
    display: flex;
}

.scenario-picker {
    margin-top: 20px;
    height: 32px;
    width: 280px;
    font-size: 13px;
    padding: 5px;
}

.scenario-section
    .add-scenario-button,
    .add-scenario-done-button,
    .add-scenario-cancel-button,
    .scenario-settings-button,
    .rename-scenario-done-button,
    .rename-scenario-cancel-button {
    margin-top: 20px;
    height: 32px;
    font-size: 16px;
    margin-left: 10px;
}

.add-scenario-done-button, .rename-scenario-done-button {
    width: 55px;
    font-size: 16px;
}

.add-scenario-cancel-button, .rename-scenario-cancel-button {
    width: 65px;
    font-size: 16px;
}

.add-scenario-form, .rename-scenario-form {
    margin-top: 20px;
    height: 19px;
    padding: 5px;
    font-size: 16px;
    width: 255px;
}

.forward-date-arrow, .back-date-arrow {
    height: 32px;
    font-size: 22px;
    font-weight: bold;
}

.forward-date-arrow {
    margin-left: 10px;
}

.back-date-arrow {
    margin-right: 10px;
}

.scenario-truck-editor .trailer-picker-section, .driver-picker-section {
    display: flex;
    margin-bottom: 20px;
}

.trailer-picker-section .trailer-picker {
    height: 20px;
    margin-left: 13px;
}

.driver-picker-section .driver-picker {
    margin-left: 15px;
}

.scenario-truck-editor-buttons {
    margin-top: 10px;
    display: flex;
}

.scenario-truck-editor-cancel {
    margin-left: 250px;
}

.scenario-truck-editor-done {
    margin-left: 20px;
}

.scenario-truck-context-menu {
    z-index: 2;
}  

.scenario-truck-editor .comments-section {
    margin-top: 15px;
}

.scenario-truck-editor .comments-section textarea {
    resize: none;
}

.scenario-truck-editor .dispatcher-comments {
    margin-top: 15px;
}

.scenario-truck-editor .truck-picker-section {
    margin-bottom: 15px;
}

.scenario-truck-editor .truck-picker-section .truck-selection {
    margin-left: 20px;
}

.scenario-truck-context-menu .react-contextmenu {
    font-size: 16px;
}

.scenario-truck-context-menu .react-contextmenu--visible {
    z-index: 20;
}

.workday-duration-section {
    margin-bottom: 20px;
}

.workday-duration-section .workday-duration-input {
    margin-left: 15px;
    width: 60px;
}

.location-schedule-context-menu .react-contextmenu-item:not(.context-menu-title) {
    font-size: 16px;
}

.location-schedule-comment-modal .display-name {
    margin-bottom: 10px;
}

.location-schedule-comment-modal .property-labels,
  .delay-label,
  .comment-label,
  .dispatcher-comment-label {
    font-size: 14px;
}

.location-schedule-comment-modal .delay-section, .comment-section {
    margin-bottom: 10px;
}

.delay-section .load-time-delay-checker {
    margin-bottom: 15px;
    font-size: 14px;
}

.location-schedule-comment-modal .end-time {
    margin-bottom: 10px;
}

.location-schedule-comment-modal .comment-field {
    margin-bottom: 15px;
}

.location-schedule-comment-modal .cancel {
    margin-left: 250px;
}

.location-schedule-comment-modal .done {
    margin-left: 15px;
}

.location-schedule-comment-modal .loader-section {
    margin-bottom: 10px;
}

.location-delay {
    z-index: 2;
    box-sizing: border-box;
    outline: 3px solid #8194c5de;
    outline-offset: -3px;
}

.transit.location-delay {
    outline-color: #8194c5de;
    background-image: linear-gradient(45deg, #8194c5 8.33%, #ffffff 8.33%, #ffffff 50%, #8194c5 50%, #8194c5 58.33%, #ffffff 58.33%, #ffffff 100%);
    background-size: 16.97px 16.97px;
}

.block.location-delay {
    outline-color: #bcd192d6;

    background-image: linear-gradient(45deg, #bcd192d6 8.33%, #ffffff 8.33%, #ffffff 50%, #bcd192d6 50%, #bcd192d6 58.33%, #ffffff 58.33%, #ffffff 100%);
    background-size: 16.97px 16.97px;
}

.dryland.location-delay {
    outline-color: #cea3a3be;

    background-image: linear-gradient(45deg, #cea3a3be 8.33%, #ffffff 8.33%, #ffffff 50%, #cea3a3be 50%, #cea3a3be 58.33%, #ffffff 58.33%, #ffffff 100%);
    background-size: 16.97px 16.97px;
}

.yard.location-delay {
    outline-color: #a1d6e2d6;
    background-image: linear-gradient(45deg, #a1d6e2d6 8.33%, #ffffff 8.33%, #ffffff 50%, #a1d6e2d6 50%, #a1d6e2d6 58.33%, #ffffff 58.33%, #ffffff 100%);
    background-size: 16.97px 16.97px;
}

.schedule-scroll-top, .schedule-scroll-bottom {
    height: 2px;
    width: 2298px;
    background-color:  #7300e6;
    position: relative;
}

.schedule-scroll-top {
    margin-bottom: 5px;
}

.schedule-scroll-bottom {
    margin-bottom: 15px;
}

.schedule-scroll-bottom:hover, .schedule-scroll-top:hover {
    cursor: pointer;
}

.scroll-icon {
    -webkit-transform:  scale(3, 1);
            transform:  scale(3, 1);
}

.schedule-scroll-bottom .scroll-bottom-button {
    margin-top: -5px;
}

.scroll-top-button, .scroll-bottom-button {
    border: none;
    background-color: #ffffff00; 
    color: #9b9b9b;
    font-size: 20px;
    height: 25px;
    width: 30px;
    margin-left: 70px;
    cursor: pointer;
}

.scenario-truck-delete-modal .delete-modal-buttons {
    margin-left: 220px;
}

.scenario-truck-delete-modal .delete-button {
    margin-left: 15px;
}

.scenario-truck-delete-modal .delete-share-button {
    margin-top: 15px;
    width: 127px;
}

.location-item-container {
    text-align: center;
    display: flex;
}

.dashboard-block-loads {
    position: absolute;
    top: 20px;
    left: 240px;
    height: 250px;
    overflow-y: scroll;
    border-bottom: 1px solid #dcdcdc;
    border-left: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
}

.dashboard-block-loads .headers {
    display: flex;
    margin-left: 20px;
    margin-top: 5px;
}

.dashboard-block-loads .unordered-label {
    margin-left: 20px;
}

.dashboard-block-loads .comment {
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 0.5px solid #bbbbbb;
    width: 350px;
}

.dashboard-block-loads .comment .comment-text {
    font-weight: bold;
}

.dashboard-block-loads .dashboard-block-load-row {
    margin-left: 15px;
}

.dashboard-block-load-row .assigned-value {
    margin-left: 30px;
}

.dashboard-block-loads .headers div {
    margin-right: 15px;
}

.dashboard-block-load-row {
    display: flex;
    margin-bottom: 10px;
}

.dashboard-block-load-row div {
    text-align: end;
    width: 35px;
}

.dashboard-block-load-row .total-value {
    margin-left: 20px;
}

.dashboard-block-load-row .assigned-value {
    margin-left: 30px;
}

.dashboard-block-load-row .remaining-value {
    margin-left: 40px;
}

.dashboard-block-load-row .name-value {
    margin-left: 65px;
}

/* .load-inventory-status {
    margin-left: 25px;
    margin-top: 20px;
} */

.inventory-status-and-count-container {
    display: flex;
    margin-left: 25px;
    margin-top: 20px;
}

.truck-count {
    margin-left: 25px;
    display: flex;
    /* margin-top: 20px; */
}

.truck-count-per-operatingUnit {
    margin-left: 25px;
}

.copy-scenario-modal .copy-cancel {
    margin-left: 130px;
}

.copy-scenario-modal .copy-done {
    margin-left: 15px;
}

.copy-scenario-modal .copy-chosen-date-label {
    margin-left: 20px;
}

.copy-prompt {
    margin-bottom: 20px;
}

.load-inventory-error {
    font-size: 20px;
}

.loader-picker-modal .loader-picker {
    margin-top: 15px;
}

.loader-picker-modal .loader-modal-buttons {
    margin-left: 200px;
}

.loader-modal-buttons .done-button {
    margin-left: 25px;
}

.loader-picker-modal .loader-picker {
    min-width: 130px;
}

.send-pdf-modal-buttons {
    margin-top: 20px;
    margin-left: 160px;
}

.send-pdf-modal-buttons .cancel-button {
    margin-right: 20px;
}

.user-settings-button, .view-selection-button {
    position: relative;
    font-size: 16px;
    top: 20px;
    height: 32px;
    margin-left: 10px;
}

.sort-schedule-button {
    position: absolute;
    margin-top: 5px;
}

.no-scenarios-available {
    margin-top: 15px;
    font-size: 28px;
}

.location-item-container .yard-name-hover {
    display: none;
}

.location-item.yard:hover {
    z-index: 3;
}

.location-item.yard:hover > .yard-name-hover {
    display: block;
    position: absolute;
    margin-left: 10px;
    width: 60px;
    background: #a1d6e2;
    padding: 10px;
    z-index: 3;
}

.operating-unit-selection-container .operating-unit-select-button {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 16px;
    height: 32px;
}

.operating-unit-filter-options-container .operating-unit-filter-row {
    margin-bottom: 10px;
}

.operating-unit-modal-buttons {
    float: right;
    margin-right: 30px;
}

.operating-unit-modal-buttons .done-button {
    margin-left: 20px;
}

.operating-unit-filter-options-container .select-all-button {
    margin-bottom: 10px;
}

.scenario-delete-modal-buttons {
    float: right;
    margin-right: 10px;
}

.scenario-delete-modal-buttons .delete-button {
    margin-left: 20px;
}

.operating-unit-label-container .operating-unit-name-label {
    margin-bottom: 0px;
}

.operating-unit-label-container .operating-unit-divider {
    position: relative;
    border-top: 0.5px solid #808080;
}

.publish-scenario-modal .publish-button {
    margin-left: 15px;
}

.publish-scenario-modal .button-container {
    margin-top: 20px;
    margin-left: 110px;
}


.scenario-truck-share-buttons {
    margin-top: 10px;
    display: flex;
}

.scenario-truck-share-cancel {
    margin-left: 250px;
}

.scenario-truck-share-done {
    margin-left: 20px;
}

.scenario-truck-share .comments-section {
    margin-top: 15px;
}

.scenario-truck-share .comments-section textarea {
    resize: none;
}

.scenario-truck-share .dispatcher-comments {
    margin-top: 15px;
}


.scenario-truck-share .operating-unit-picker-section {
    margin-bottom: 15px;
}

.operating-unit-picker-section .operating-unit-picker {
    margin-top: 15px;
    height: 20px;
    margin-left: 13px;
}

.driver-experience-picker {
    margin-left: 10px;
    width: 95px;
}

.ranking-modal-buttons .done-button {
    margin-top: 15px;
    margin-left: 290px;
    width: 75px;
}

.destination-max-capacity-modal-buttons .done-button {
    margin-top: 15px;
    margin-left: 290px;
    width: 75px;
}

.dashboard-yard .sleeper-load-label {
    margin-bottom: 5px;
}

.yard-sleeper-editor {
    margin-top: 10px;
}

.yard-sleeper-editor .destination-picker {
    margin-top: 5px;
}

.yard-sleeper-editor .destination-label {
    margin-right: 10px;
}

.assign-sleeper-modal .assign-sleeper-modal-buttons {
    float: right;
}

.assign-sleeper-modal .assign-sleeper-modal-buttons .cancel-button {
    margin-right: 15px;
}

.driver-block-no-history {
    border-top: 2px solid red !important;
}

.import-sleepers-modal-buttons {
    float: right;
    margin-top: 30px;
}

.import-sleepers-modal-buttons .done-button {
    margin-left: 10px;
}


.import-sleepers-modal .import-prompt {
    margin-bottom: 20px;
}

.sleeper-load-editor .labels {
    margin-bottom: 10px;
    margin-left: 30px;
}

.sleeper-load-editor .destination-label {
    margin-left: 75px;
}

.sleeper-load-editor .originBlock-label {
    margin-left: 118px;
}

.sleeper-load-editor .sleeper-load-row {
    margin-bottom: 5px;
}

.sleeper-load-editor .sleeper-load-buttons {
    margin-left: 230px;
  }
  
  .sleeper-load-editor .sleeper-load-buttons button {
    font-size: 16px;
  }
  
  .sleeper-load-editor .done {
    margin-left: 20px;
  }

.sleeper-load-row .truck-picker {
    width: 8em;
}

.sleeper-load-row .originBlock-picker {
    width: 8em;
    margin-left: 50px;
}

.sleeper-load-row .destination-picker {
    margin-left: 50px;
    width: 8em;
}

.sleeper-load-row .delete-sleeper-load-row {
    background-color: #e8e8e8;
    border-width: 0px; 
    cursor: pointer;
}

.sleeper-load-row .warning-sign {
    display: inline-block;
    width: 30px;
}

.swap-schedule-activity-modal .target-location-picker-section .location-picker 
.swap-schedule-activity-modal .target-location-picker-section .destination-for-block-picker { 
    margin-top: 10px;
    width: 100px;
}


.swap-schedule-activity-modal .swap-modal-buttons {
    margin-top: 90px;
    margin-left: 220px;
}

.swap-schedule-activity-modal .swap-modal-buttons .done-button {
    margin-left: 15px;
}


.swap-schedule-activity-modal .target-location-picker-section {
    display: flex;
    margin-top: 20px;
}

.swap-schedule-activity-modal .swap-modal-loader-section,
.swap-schedule-activity-modal .swap-modal-destination-for-block-section {
    margin-left: 35px;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  
.Selectable .DayPicker-Day {
    border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}
  
.Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}

.rollup-modal-buttons {
    float: right;
    margin-top: 15px;
}

.rollup-modal-done-button {
    margin-left: 15px;
}

.react-sanfona {
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 35px;
  }
  
  .react-sanfona-item-title {
    background-color: #fafafa;
    border-top: 1px solid #ccc;
    color: #333;
    padding: 5px;
  }
  
  .react-sanfona-item:first-child .react-sanfona-item-title {
    border-top: none;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }

  .navistream-window .header .title {
      display: inline;
      margin-left: 200px;
  }
  
  .navistream-window .header .refresh-button {
      height: 30px;
      width: 50px;
      float: right;
  }

  .reopen-scenario-modal-buttons {
    float: right;
    margin-top: 25px;
  }

  .reopen-scenario-modal-done-button {
    margin-left: 15px;
  }

  .block-third-party-check {
      margin-bottom: 15px;
      font-size: 14px;
  }

.configuration-page {
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
}

.configuration-view-categories {
  display: flex;
  flex-flow: column;
  width: 200px;
}

.configuration-view-button {
  margin-top: 10px;
  font-size: 20px;
  text-transform: uppercase;
  text-align: left;
}

.configuration-forms {
  margin-top: 10px;
  margin-left: 20px;
}

.block-list, .destination-list, .truck-list, .driver-list, .loader-list {
  display: flex;
  flex-flow: column;
}

.block-edit-panel .block-fields, 
.destination-edit-panel .destination-field, 
.truck-edit-panel .truck-field,
.driver-edit-panel .driver-field,
.loader-edit-panel .loader-field,
.yard-edit-panel .yard-field,
.operator-edit-panel .operator-field,
.operating-unit-edit-panel .operating-unit-field,
.mark-edit-panel .mark-field,
.errorCode-edit-panel .Code-field, .errorCode-edit-panel .description-field,
.vendor-edit-panel .vendor-field,
.hammerMark-edit-panel .hammerMark-field,
.contractor-edit-panel .contractor-field,
.faller-edit-panel .faller-field,
.yarder-edit-panel .yarder-field,
.processor-edit-panel .processor-field,
.truckCompany-edit-panel .truckCompany-field {
  display: flex;
  margin-top: 15px;
}

.block-fields textarea,
.destination-field textarea,
.driver-field textarea,
.yard-field textarea, 
.operator-field textarea,
.operating-unit-field textarea,
.mark-field textarea,
.error-code-field textarea,
.report-field textarea {
  margin-left: 15px;
  resize: none;
}
/* 
.block-edit-panel input,  */
.destination-edit-panel input, 
.truck-edit-panel input,
.driver-edit-panel input,
.yard-edit-panel input,
.operator-edit-panel input,
.operating-unit-field input,
.mark-edit-panel input,
.errorCode-edit-panel input,
.vendor-edit-panel input,
.hammerMark-edit-panel input,
.contractor-edit-panel input,
.faller-edit-panel input,
.yarder-edit-panel input,
.processor-edit-panel input,
.report-edit-panel input,
.truckCompany-edit-panel input {
  margin-left: 15px;
  display: inline-block;
}

.driver-edit-panel .email-field textarea,
.operator-edit-panel .email-field textarea,
.report-edit-panel .email-field textarea {
  margin-left: 18px;
}

.location-type-selection {
  display: flex;
  margin-top: 15px;
}

.block-edit-panel .destination-picker {
  flex-flow: column;
  background: #f0f8ff;
  width: 320px;
  margin-left: 25px;
  margin-top: 20px;
}

.destination-picker-label {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.destination-picker-row {
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 10px;
}

.destination-picker-row .destination-name {
  display: inline-block;
  width: 70px;
  text-align: right;
}

.destination-picker-row .time-to-destination {
  width: 120px;
}

.remove-prompt-modal .delete-modal-buttons .cancel-button {
  margin-left: 200px;
}

.remove-prompt-modal .delete-modal-buttons .delete-button {
  margin-left: 20px;
}

.change-view-button {
  margin-top: 20px;
  height: 32px;
  font-size: 16px;
  margin-left: 10px;
}

.block-edit-panel,
.destination-edit-panel,
.truck-edit-panel,
.driver-edit-panel,
.loader-edit-panel,
.yard-edit-panel,
.operator-edit-panel,
.errorCode-edit-panel,
.mark-edit-panel,
.vendor-edit-panel,
.hammerMark-edit-panel,
.contractor-edit-panel,
.faller-edit-panel,
.yarder-edit-panel,
.processor-edit-panel,
.operating-unit-edit-panel,
.report-edit-panel,
.truckCompany-edit-panel {

  display: flex;
}

.block-edit-panel .remove-block,
.destination-edit-panel .remove-destination,
.truck-edit-panel .remove-truck,
.driver-edit-panel .remove-driver,
.loader-edit-panel .remove-loader,
.yard-edit-panel .remove-yard,
.errorCode-edit-panel .remove-errorCode,
.operating-unit-edit-panel .remove-operating-unit,
.mark-edit-panel .remove-mark,
.operator-edit-panel .remove-operator,
.vendor-edit-panel .remove-vendor,
.hammerMark-edit-panel .remove-hammerMark,
.contractor-edit-panel .remove-contractor,
.faller-edit-panel .remove-faller,
.yarder-edit-panel .remove-yarder,
.processor-edit-panel .remove-processor,
.truckCompany-edit-panel .remove-truckCompany{

  height: 25px;
  margin-top: 10px;
}

.block-field-edit-buttons {
  margin-top: 15px;
}

.block-field-edit-buttons .done-button, 
.destination-field-edit-buttons .done-button,
.truck-field-edit-buttons .done-button,
.driver-field-edit-buttons .done-button,
.loader-field-edit-buttons .done-button,
.yard-field-edit-buttons .done-button,
.operator-field-edit-buttons .done-button,
.error-code-field-edit-buttons .done-button,
.mark-field-edit-buttons .done-button,
.operating-unit-field-edit-buttons .done-button,
.vendor-field-edit-buttons .done-button,
.hammerMark-field-edit-buttons .done-button,
.contractor-field-edit-buttons .done-button,
.faller-field-edit-buttons .done-button,
.yarder-field-edit-buttons .done-button,
.processor-field-edit-buttons .done-button,
.report-field-edit-buttons .done-button,
.truckCompany-field-edit-buttons .done-button {

  margin-left: 20px;
}

.block-field-edit-buttons .done-button:disabled,
.destination-field-edit-buttons .done-button:disabled,
.truck-field-edit-buttons.done-button:disabled,
.driver-field-edit-buttons.done-button:disabled,
.loader-field-edit-buttons .done-button:disabled,
.yard-field-edit-buttons .done-button:disabled,
.operator-field-edit-buttons .done-button:disabled,
.error-code-field-edit-buttons .done-button:disabled,
.mark-field-edit-buttons .done-button:disabled,
.operating-unit-field-edit-buttons .done-button:disabled,
.vendor-field-edit-buttons .done-button:disabled,
.hammerMark-field-edit-buttons .done-button:disabled,
.contractor-field-edit-buttons .done-button:disabled,
.faller-field-edit-buttons .done-button:disabled,
.yarder-field-edit-buttons .done-button:disabled,
.processor-field-edit-buttons .done-button:disabled,
.report-field-edit-buttons .done-button:disabled,
.truckCompany-field-edit-buttons .done-button:disabled {

  color: #b6b6b6;
}

.destination-field-edit-buttons,
.truck-field-edit-buttons,
.driver-field-edit-buttons,
.loader-field-edit-buttons,
.yard-field-edit-buttons,
.operator-field-edit-buttons,
.error-code-field-edit-buttons,
.mark-field-edit-buttons,
.operating-unit-field-edit-buttons,
.error-code-field-edit-buttons,
.mark-field-edit-buttons,
.vendor-field-edit-buttons,
.hammerMark-field-edit-buttons,
.contractor-field-edit-buttons,
.faller-field-edit-buttons,
.yarder-field-edit-buttons,
.processor-field-edit-buttons,
.report-field-edit-buttons,
.truckCompany-field-edit-buttons {

  margin-top: 20px;
}

.block-config-buttons,
.destination-config-buttons,
.truck-config-buttons,
.driver-config-buttons,
.loader-config-buttons,
.yard-config-buttons,
.operating-unit-config-buttons,
.operator-config-buttons,
.errorCode-config-buttons,
.mark-config-buttons,
.vendor-config-buttons,
.hammerMark-config-buttons,
.contractor-config-buttons,
.faller-config-buttons,
.yarder-config-buttons,
.processor-config-buttons,
.report-config-buttons,
.truckCompany-config-buttons {
  display: flex;
  flex-flow: column;
  margin-left: 40px;
}

.config-comment-section {
  margin-top: 20px;
  margin-left: 40px;
}

.activate-button {
  margin-top: 20px;
  height: 25px;
}

.axle-selection {
  margin-left: 28px;
}

.bulk-items-list {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  height: 20em;
}

.bulk-activation-button {
  margin-left: 20px;
}

.block-history-button {
  margin-left: 20px;
}

.driver-history-button {
  margin-left: 20px;
}

.bulk-activation-row {
  margin-bottom: 10px;
  width: 7em;
}

.bulk-activation-cancel-button {
  margin-left: 270px;
}

.bulk-activation-done-button {
  margin-left: 20px;
}

.loader-field {
  display: flex;
}

.loader-edit-panel .loader-field input {
  margin-left: 15px;
}

.block-edit-panel .loader-picker {
  display: block;
}

.block-edit-panel .loader-picker-row {
  display: flex;
  margin-top: 10px;
}

.truck-edit-panel .default-truck-editor select {
  margin-left: 15px;
}

.truck-edit-panel .default-truck-editor-buttons {
  margin-top: 20px;
}

.truck-edit-panel .default-truck-editor-buttons .done-button {
  margin-left: 20px;
}

.configuration-object-selector {
  width: 250px;
}

.yard-time-editor {
  margin-top: 20px;
  margin-bottom: 10px;
  background: #f0f8ff;
  width: 320px;
  padding: 10px 0px 5px 10px;
}

.yard-time-editor .yard-edit-rows-container {
  margin-top: 20px;
}

.yard-time-editor .yard-edit-row {
  display: flex;
  margin-bottom: 10px;
}

.yard-edit-row .yard-label {
  width: 80px;
  text-align: right;
}

.yard-edit-row .time-to-yard {
  width: 50px;
}

.yard-assignment .yard-assigned-container {
  max-height: 20em;
  overflow-y: scroll;
}

.yard-assignment {
  display: flex;
  margin-top: 20px;
}

.yard-assignment .yard-assigned-row {
  display: flex;
  margin-bottom: 10px;
}

.yard-assignment .yard-assigned-row:nth-child(even) {
  background-color: #e0e0e0	;
}

.yard-assignment .yard-assigned-row .location-name {
  margin-left: 5px;
  width: 6em;
}

.yard-assignment .yard-assigned-row .yard-to-location-time {
  width: 3em;
  text-align: right;
  margin-right: 10px;
}

.yard-assignment .yards-assigned-block {
  margin-right: 30px;
}

.yard-assignment .yard-assign-label {
  margin-bottom: 10px;
}

.operators-configuration {
  margin-top: 10px;
}

.loader-operator-picker {
  margin-left: 15px;
}

.operating-unit-picker-container {
  display: flex;
  margin-top: 15px;
}

.operating-unit-picker-container .operating-unit-picker {
  margin-left: 15px;
  width: 166px;
}

.destination-operating-unit-editor {
  width: 320px;
  padding: 10px 0px 5px 10px;
  background: #fdffe8;
}

.destination-operating-unit-editor .assigned-operating-unit-label {
  margin-bottom: 10px;
}

.destination-operating-unit-editor .operating-unit-label {
  width: 80px;
  text-align: right;
}

.destination-operating-unit-editor .operating-unit-row {
  display: flex;
  margin-bottom: 5px;
}

.users-configuration {
  margin-left: 15px;
  margin-top: 10px;
}

.users-configuration .user-config-row {
  margin-bottom: 20px;
}

.user-field-editor .email-field {
  display: flex;
  margin-top: 15px;
  margin-bottom: 20px;
}

.user-field-editor .role-picker-container {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}

.user-field-editor .email-field input {
  margin-left: 15px;
}

.user-field-editor .role-picker-container .role-picker {
  margin-left: 22px;
}

.user-field-edit-buttons {
  margin-top: 15px;
}

.user-field-edit-buttons .done-button {
  margin-left: 10px;
}


.payOptions-field {
  margin-top : 10px;
  display : flex;
}

.payOptions-field-dropdown {
  margin-left : 15px;

}

.errorCode-picker-component {
  margin-top: 10px;
}

.mark-field .destination-picker {
  margin-left: 10px;
}



.blocks-for-driver-list {
  
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  height: 20em;
}


.drivers-in-block-list {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  height: 20em;
}

.block-history-modal-buttons {
  margin-left: 550px;
  margin-top: 20px;
  display: flex;   
}

.block-history-modal-buttons .done-button {
  margin-left: 15px;
}

.driver-history-modal-buttons {
  margin-left: 550px;
  margin-top: 20px;
  display: flex;   
}

.driver-history-modal-buttons .done-button {
  margin-left: 15px;
}


.block-edit-panel .vendor-picker-row {
  display: flex;
  margin-top: 10px;
}

.vendor-edit-panel .name-field {
  margin-top: 15px;
}

.vendor-edit-panel .currently-assigned-blocks {
  margin-top: 30px;
}


.hammerMark-edit-panel .name-field {
  margin-top: 15px;
}

.contractor-edit-panel .name-field {
  margin-top: 15px;
}

.block-ranking-picker {
  margin-left: 15px;
  width: 166px;
}

.block-edit-panel .loader-picker {
  display: block;
  width: 320px;
  background-color: beige;
  padding: 10px 0px 5px 10px;
}


.faller-edit-panel .name-field {
  margin-top: 15px;
}

.block-field-setting-number-input {
  margin-left: 7px;
}

.block-field-block-channel-input {
  margin-left: 15px;
}

.block-field-road-name-input {
  margin-left: 32px;
}

.block-field-preload-time-input {
  margin-left: 23px;
}

.block-field-load-time-input {
  margin-left: 43px;
}

.block-field-postload-time-input {
  margin-left: 16px;
}

.yarder-edit-panel .name-field {
  margin-top: 15px;
}

.block-edit-panel .processor-picker-row,
.block-edit-panel .yarder-picker-row,
.block-edit-panel .faller-picker-row,
.block-edit-panel .hammerMark-picker-row,
.block-edit-panel .contractor-picker-row,
.block-edit-panel .vendor-picker-row,
.block-edit-panel .loader-picker-row {
  display: flex;
  margin-top: 10px;
}

.loader-edit-panel .operator-picker-row,
.loader-edit-panel .block-picker-row {
  display: flex;
  margin-top: 10px;
}

.loader-edit-panel .operator-picker,
.loader-edit-panel .block-picker{
  display: flex;
  width: 320px;
  height: 300px;
  background-color: beige;
  padding: 10px 0px 5px 10px;
  flex-flow: column;
  flex-wrap: wrap;
  overflow-x: scroll;
}

.processor-edit-panel .name-field {
  margin-top: 15px;
}

.block-edit-panel .processor-picker,
.block-edit-panel .yarder-picker,
.block-edit-panel .hammerMark-picker,
.block-edit-panel .faller-picker,
.block-edit-panel .vendor-picker,
.block-edit-panel .contractor-picker {
  display: flex;
  width: 320px;
  height: 300px;
  background-color: beige;
  padding: 10px 0px 5px 10px;
  flex-flow: column;
  flex-wrap: wrap;
  overflow-x: scroll;
}

.block-edit-panel .processor-picker,
.block-edit-panel .faller-picker,
.block-edit-panel .vendor-picker {
  margin-left: 25px;
}

.hammerMarks-and-processors-block-field,
.yarders-and-fallers-block-field,
.contractors-and-vendors-block-field,
.yardTimeEditor-and-destination-picker-block-field {
  display: flex
}

.destination-input-label {
  display: inline-block;
  width: 100px;
  text-align: left;
}


.duplicate-object-name-modal .duplicate-object-name-modal-buttons {
  margin-top: 30px;
  margin-left: 175px;
}

.duplicate-object-name-modal .duplicate-object-name-modal-buttons .activate-button,
.duplicate-object-name-modal .duplicate-object-name-modal-buttons .recreate-button {
  margin-left: 10px
}


.duplicate-object-name-modal .duplicate-object-name-modal-buttons .done-button {
  margin-left: 100px;
  margin-top: 40px;
}


.report-edit-panel .email-field,
.report-edit-panel .name-field {
  margin-top: 15px;
  display: flex;
}

.destination-company-picker {
  margin-left: 16px;
  width: 170px;
}

.block-activity, .destination-activity {
  position: absolute;
  width: 100px;
}

.block-activity:hover, .destination-activity:hover {
  background: #ffe970;
  z-index: 10;
  cursor: pointer;
}

.vertical-time {
  height: 120px;
  width: 65px;
}

.vertical-time-markers {
  margin-top: 85px;
  width: 65px;
}

.destination-view-container .vertical-time-markers {
  margin-top: 5px;
}

.horizontal-line-separator {
  border-top: 0.5px solid #e4e4e4;
}

.time-display {
  padding: 5px 0px 0px 5px;
  box-sizing: border-box;
  font-size: 14px;
}

.block-column-header, .destination-column-header {
  width: 100px;
  padding: 5px;
  height: 30px;
  margin-right: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  z-index: 2;
}

.block-column-header {
  background: #decf63de;
}

.destination-column-header {
  background: #cea3a3be;
}

.destination-column-headers-container {
  display: flex;
  flex-flow: row;
  margin-left: 70px;
  position: fixed;
  z-index: 10;
}

.block-view-container .grid-body {
  display: flex;
}

.destination-view-container .grid-body {
  display: flex;
  margin-top: 55px;
}

.grid-body .truck-block-activities-container,
.grid-body .truck-destination-activities-container {
  display: flex;
}

.block-activity-details, .destination-activity-details {
  position: absolute;
  background: #D0DDCF;
  border: 1px solid #A0BC9F;
  width: 190px;
  padding: 10px;
  left: 110px;
  z-index: 11;
}

.block-activity-column-container {
  display: flex;
}

.block-grouping .block-grouping-name {
  text-align: center;
  background: #bcd192d6;
  padding: 5px 10px 5px 10px;
  margin-right: 10px;
  font-weight: bold;
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  z-index: 2;
}

.block-grouping .loader-headers {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 45px;
  margin-top: 10px;
  z-index: 2;
}

.block-column-header .operator-info {
  display: none;
  position: relative;
  top: 20px;
  padding: 5px;
  background: #decf63de;
  border: 1px solid #585858;
  z-index: 5;
}

.block-column-header:hover .operator-info {
  display: block; 
}

.vertical-separator {
  border-Right: 0.5px solid #e4e4e4;
  height: 2000px;
  position: absolute;
  margin-left: 110px;
  top: 0px;
}

.view-header {
  display: flex;
  margin-bottom: 1em;
}

.view-header .operating-unit-name {
  margin-left: 20px;
}

.view-header .operating-unit-name-label, .scenario-name-label {
  font-weight: bold;
}

.horizontal-time-tracker {
  border-top: 2px solid #70c6ff;
  position: absolute;
  width: 100%;
  margin-top: 135px;
  z-index: 3;
}
.loading-screen {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  background-color: gray;
  z-index: 30;
}


.loading-screen-rotate-spin-loader{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
