.configuration-page {
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
}

.configuration-view-categories {
  display: flex;
  flex-flow: column;
  width: 200px;
}

.configuration-view-button {
  margin-top: 10px;
  font-size: 20px;
  text-transform: uppercase;
  text-align: left;
}

.configuration-forms {
  margin-top: 10px;
  margin-left: 20px;
}

.block-list, .destination-list, .truck-list, .driver-list, .loader-list {
  display: flex;
  flex-flow: column;
}

.block-edit-panel .block-fields, 
.destination-edit-panel .destination-field, 
.truck-edit-panel .truck-field,
.driver-edit-panel .driver-field,
.loader-edit-panel .loader-field,
.yard-edit-panel .yard-field,
.operator-edit-panel .operator-field,
.operating-unit-edit-panel .operating-unit-field,
.mark-edit-panel .mark-field,
.errorCode-edit-panel .Code-field, .errorCode-edit-panel .description-field,
.vendor-edit-panel .vendor-field,
.hammerMark-edit-panel .hammerMark-field,
.contractor-edit-panel .contractor-field,
.faller-edit-panel .faller-field,
.yarder-edit-panel .yarder-field,
.processor-edit-panel .processor-field,
.truckCompany-edit-panel .truckCompany-field {
  display: flex;
  margin-top: 15px;
}

.block-fields textarea,
.destination-field textarea,
.driver-field textarea,
.yard-field textarea, 
.operator-field textarea,
.operating-unit-field textarea,
.mark-field textarea,
.error-code-field textarea,
.report-field textarea {
  margin-left: 15px;
  resize: none;
}
/* 
.block-edit-panel input,  */
.destination-edit-panel input, 
.truck-edit-panel input,
.driver-edit-panel input,
.yard-edit-panel input,
.operator-edit-panel input,
.operating-unit-field input,
.mark-edit-panel input,
.errorCode-edit-panel input,
.vendor-edit-panel input,
.hammerMark-edit-panel input,
.contractor-edit-panel input,
.faller-edit-panel input,
.yarder-edit-panel input,
.processor-edit-panel input,
.report-edit-panel input,
.truckCompany-edit-panel input {
  margin-left: 15px;
  display: inline-block;
}

.driver-edit-panel .email-field textarea,
.operator-edit-panel .email-field textarea,
.report-edit-panel .email-field textarea {
  margin-left: 18px;
}

.location-type-selection {
  display: flex;
  margin-top: 15px;
}

.block-edit-panel .destination-picker {
  flex-flow: column;
  background: #f0f8ff;
  width: 320px;
  margin-left: 25px;
  margin-top: 20px;
}

.destination-picker-label {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.destination-picker-row {
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 10px;
}

.destination-picker-row .destination-name {
  display: inline-block;
  width: 70px;
  text-align: right;
}

.destination-picker-row .time-to-destination {
  width: 120px;
}

.remove-prompt-modal .delete-modal-buttons .cancel-button {
  margin-left: 200px;
}

.remove-prompt-modal .delete-modal-buttons .delete-button {
  margin-left: 20px;
}

.change-view-button {
  margin-top: 20px;
  height: 32px;
  font-size: 16px;
  margin-left: 10px;
}

.block-edit-panel,
.destination-edit-panel,
.truck-edit-panel,
.driver-edit-panel,
.loader-edit-panel,
.yard-edit-panel,
.operator-edit-panel,
.errorCode-edit-panel,
.mark-edit-panel,
.vendor-edit-panel,
.hammerMark-edit-panel,
.contractor-edit-panel,
.faller-edit-panel,
.yarder-edit-panel,
.processor-edit-panel,
.operating-unit-edit-panel,
.report-edit-panel,
.truckCompany-edit-panel {

  display: flex;
}

.block-edit-panel .remove-block,
.destination-edit-panel .remove-destination,
.truck-edit-panel .remove-truck,
.driver-edit-panel .remove-driver,
.loader-edit-panel .remove-loader,
.yard-edit-panel .remove-yard,
.errorCode-edit-panel .remove-errorCode,
.operating-unit-edit-panel .remove-operating-unit,
.mark-edit-panel .remove-mark,
.operator-edit-panel .remove-operator,
.vendor-edit-panel .remove-vendor,
.hammerMark-edit-panel .remove-hammerMark,
.contractor-edit-panel .remove-contractor,
.faller-edit-panel .remove-faller,
.yarder-edit-panel .remove-yarder,
.processor-edit-panel .remove-processor,
.truckCompany-edit-panel .remove-truckCompany{

  height: 25px;
  margin-top: 10px;
}

.block-field-edit-buttons {
  margin-top: 15px;
}

.block-field-edit-buttons .done-button, 
.destination-field-edit-buttons .done-button,
.truck-field-edit-buttons .done-button,
.driver-field-edit-buttons .done-button,
.loader-field-edit-buttons .done-button,
.yard-field-edit-buttons .done-button,
.operator-field-edit-buttons .done-button,
.error-code-field-edit-buttons .done-button,
.mark-field-edit-buttons .done-button,
.operating-unit-field-edit-buttons .done-button,
.vendor-field-edit-buttons .done-button,
.hammerMark-field-edit-buttons .done-button,
.contractor-field-edit-buttons .done-button,
.faller-field-edit-buttons .done-button,
.yarder-field-edit-buttons .done-button,
.processor-field-edit-buttons .done-button,
.report-field-edit-buttons .done-button,
.truckCompany-field-edit-buttons .done-button {

  margin-left: 20px;
}

.block-field-edit-buttons .done-button:disabled,
.destination-field-edit-buttons .done-button:disabled,
.truck-field-edit-buttons.done-button:disabled,
.driver-field-edit-buttons.done-button:disabled,
.loader-field-edit-buttons .done-button:disabled,
.yard-field-edit-buttons .done-button:disabled,
.operator-field-edit-buttons .done-button:disabled,
.error-code-field-edit-buttons .done-button:disabled,
.mark-field-edit-buttons .done-button:disabled,
.operating-unit-field-edit-buttons .done-button:disabled,
.vendor-field-edit-buttons .done-button:disabled,
.hammerMark-field-edit-buttons .done-button:disabled,
.contractor-field-edit-buttons .done-button:disabled,
.faller-field-edit-buttons .done-button:disabled,
.yarder-field-edit-buttons .done-button:disabled,
.processor-field-edit-buttons .done-button:disabled,
.report-field-edit-buttons .done-button:disabled,
.truckCompany-field-edit-buttons .done-button:disabled {

  color: #b6b6b6;
}

.destination-field-edit-buttons,
.truck-field-edit-buttons,
.driver-field-edit-buttons,
.loader-field-edit-buttons,
.yard-field-edit-buttons,
.operator-field-edit-buttons,
.error-code-field-edit-buttons,
.mark-field-edit-buttons,
.operating-unit-field-edit-buttons,
.error-code-field-edit-buttons,
.mark-field-edit-buttons,
.vendor-field-edit-buttons,
.hammerMark-field-edit-buttons,
.contractor-field-edit-buttons,
.faller-field-edit-buttons,
.yarder-field-edit-buttons,
.processor-field-edit-buttons,
.report-field-edit-buttons,
.truckCompany-field-edit-buttons {

  margin-top: 20px;
}

.block-config-buttons,
.destination-config-buttons,
.truck-config-buttons,
.driver-config-buttons,
.loader-config-buttons,
.yard-config-buttons,
.operating-unit-config-buttons,
.operator-config-buttons,
.errorCode-config-buttons,
.mark-config-buttons,
.vendor-config-buttons,
.hammerMark-config-buttons,
.contractor-config-buttons,
.faller-config-buttons,
.yarder-config-buttons,
.processor-config-buttons,
.report-config-buttons,
.truckCompany-config-buttons {
  display: flex;
  flex-flow: column;
  margin-left: 40px;
}

.config-comment-section {
  margin-top: 20px;
  margin-left: 40px;
}

.activate-button {
  margin-top: 20px;
  height: 25px;
}

.axle-selection {
  margin-left: 28px;
}

.bulk-items-list {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  height: 20em;
}

.bulk-activation-button {
  margin-left: 20px;
}

.block-history-button {
  margin-left: 20px;
}

.driver-history-button {
  margin-left: 20px;
}

.bulk-activation-row {
  margin-bottom: 10px;
  width: 7em;
}

.bulk-activation-cancel-button {
  margin-left: 270px;
}

.bulk-activation-done-button {
  margin-left: 20px;
}

.loader-field {
  display: flex;
}

.loader-edit-panel .loader-field input {
  margin-left: 15px;
}

.block-edit-panel .loader-picker {
  display: block;
}

.block-edit-panel .loader-picker-row {
  display: flex;
  margin-top: 10px;
}

.truck-edit-panel .default-truck-editor select {
  margin-left: 15px;
}

.truck-edit-panel .default-truck-editor-buttons {
  margin-top: 20px;
}

.truck-edit-panel .default-truck-editor-buttons .done-button {
  margin-left: 20px;
}

.configuration-object-selector {
  width: 250px;
}

.yard-time-editor {
  margin-top: 20px;
  margin-bottom: 10px;
  background: #f0f8ff;
  width: 320px;
  padding: 10px 0px 5px 10px;
}

.yard-time-editor .yard-edit-rows-container {
  margin-top: 20px;
}

.yard-time-editor .yard-edit-row {
  display: flex;
  margin-bottom: 10px;
}

.yard-edit-row .yard-label {
  width: 80px;
  text-align: right;
}

.yard-edit-row .time-to-yard {
  width: 50px;
}

.yard-assignment .yard-assigned-container {
  max-height: 20em;
  overflow-y: scroll;
}

.yard-assignment {
  display: flex;
  margin-top: 20px;
}

.yard-assignment .yard-assigned-row {
  display: flex;
  margin-bottom: 10px;
}

.yard-assignment .yard-assigned-row:nth-child(even) {
  background-color: #e0e0e0	;
}

.yard-assignment .yard-assigned-row .location-name {
  margin-left: 5px;
  width: 6em;
}

.yard-assignment .yard-assigned-row .yard-to-location-time {
  width: 3em;
  text-align: right;
  margin-right: 10px;
}

.yard-assignment .yards-assigned-block {
  margin-right: 30px;
}

.yard-assignment .yard-assign-label {
  margin-bottom: 10px;
}

.operators-configuration {
  margin-top: 10px;
}

.loader-operator-picker {
  margin-left: 15px;
}

.operating-unit-picker-container {
  display: flex;
  margin-top: 15px;
}

.operating-unit-picker-container .operating-unit-picker {
  margin-left: 15px;
  width: 166px;
}

.destination-operating-unit-editor {
  width: 320px;
  padding: 10px 0px 5px 10px;
  background: #fdffe8;
}

.destination-operating-unit-editor .assigned-operating-unit-label {
  margin-bottom: 10px;
}

.destination-operating-unit-editor .operating-unit-label {
  width: 80px;
  text-align: right;
}

.destination-operating-unit-editor .operating-unit-row {
  display: flex;
  margin-bottom: 5px;
}

.users-configuration {
  margin-left: 15px;
  margin-top: 10px;
}

.users-configuration .user-config-row {
  margin-bottom: 20px;
}

.user-field-editor .email-field {
  display: flex;
  margin-top: 15px;
  margin-bottom: 20px;
}

.user-field-editor .role-picker-container {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}

.user-field-editor .email-field input {
  margin-left: 15px;
}

.user-field-editor .role-picker-container .role-picker {
  margin-left: 22px;
}

.user-field-edit-buttons {
  margin-top: 15px;
}

.user-field-edit-buttons .done-button {
  margin-left: 10px;
}


.payOptions-field {
  margin-top : 10px;
  display : flex;
}

.payOptions-field-dropdown {
  margin-left : 15px;

}

.errorCode-picker-component {
  margin-top: 10px;
}

.mark-field .destination-picker {
  margin-left: 10px;
}



.blocks-for-driver-list {
  
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  height: 20em;
}


.drivers-in-block-list {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  height: 20em;
}

.block-history-modal-buttons {
  margin-left: 550px;
  margin-top: 20px;
  display: flex;   
}

.block-history-modal-buttons .done-button {
  margin-left: 15px;
}

.driver-history-modal-buttons {
  margin-left: 550px;
  margin-top: 20px;
  display: flex;   
}

.driver-history-modal-buttons .done-button {
  margin-left: 15px;
}


.block-edit-panel .vendor-picker-row {
  display: flex;
  margin-top: 10px;
}

.vendor-edit-panel .name-field {
  margin-top: 15px;
}

.vendor-edit-panel .currently-assigned-blocks {
  margin-top: 30px;
}


.hammerMark-edit-panel .name-field {
  margin-top: 15px;
}

.contractor-edit-panel .name-field {
  margin-top: 15px;
}

.block-ranking-picker {
  margin-left: 15px;
  width: 166px;
}

.block-edit-panel .loader-picker {
  display: block;
  width: 320px;
  background-color: beige;
  padding: 10px 0px 5px 10px;
}


.faller-edit-panel .name-field {
  margin-top: 15px;
}

.block-field-setting-number-input {
  margin-left: 7px;
}

.block-field-block-channel-input {
  margin-left: 15px;
}

.block-field-road-name-input {
  margin-left: 32px;
}

.block-field-preload-time-input {
  margin-left: 23px;
}

.block-field-load-time-input {
  margin-left: 43px;
}

.block-field-postload-time-input {
  margin-left: 16px;
}

.yarder-edit-panel .name-field {
  margin-top: 15px;
}

.block-edit-panel .processor-picker-row,
.block-edit-panel .yarder-picker-row,
.block-edit-panel .faller-picker-row,
.block-edit-panel .hammerMark-picker-row,
.block-edit-panel .contractor-picker-row,
.block-edit-panel .vendor-picker-row,
.block-edit-panel .loader-picker-row {
  display: flex;
  margin-top: 10px;
}

.loader-edit-panel .operator-picker-row,
.loader-edit-panel .block-picker-row {
  display: flex;
  margin-top: 10px;
}

.loader-edit-panel .operator-picker,
.loader-edit-panel .block-picker{
  display: flex;
  width: 320px;
  height: 300px;
  background-color: beige;
  padding: 10px 0px 5px 10px;
  flex-flow: column;
  flex-wrap: wrap;
  overflow-x: scroll;
}

.processor-edit-panel .name-field {
  margin-top: 15px;
}

.block-edit-panel .processor-picker,
.block-edit-panel .yarder-picker,
.block-edit-panel .hammerMark-picker,
.block-edit-panel .faller-picker,
.block-edit-panel .vendor-picker,
.block-edit-panel .contractor-picker {
  display: flex;
  width: 320px;
  height: 300px;
  background-color: beige;
  padding: 10px 0px 5px 10px;
  flex-flow: column;
  flex-wrap: wrap;
  overflow-x: scroll;
}

.block-edit-panel .processor-picker,
.block-edit-panel .faller-picker,
.block-edit-panel .vendor-picker {
  margin-left: 25px;
}

.hammerMarks-and-processors-block-field,
.yarders-and-fallers-block-field,
.contractors-and-vendors-block-field,
.yardTimeEditor-and-destination-picker-block-field {
  display: flex
}

.destination-input-label {
  display: inline-block;
  width: 100px;
  text-align: left;
}


.duplicate-object-name-modal .duplicate-object-name-modal-buttons {
  margin-top: 30px;
  margin-left: 175px;
}

.duplicate-object-name-modal .duplicate-object-name-modal-buttons .activate-button,
.duplicate-object-name-modal .duplicate-object-name-modal-buttons .recreate-button {
  margin-left: 10px
}


.duplicate-object-name-modal .duplicate-object-name-modal-buttons .done-button {
  margin-left: 100px;
  margin-top: 40px;
}


.report-edit-panel .email-field,
.report-edit-panel .name-field {
  margin-top: 15px;
  display: flex;
}

.destination-company-picker {
  margin-left: 16px;
  width: 170px;
}
