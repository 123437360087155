.loading-screen {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  background-color: gray;
  z-index: 30;
}


.loading-screen-rotate-spin-loader{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}