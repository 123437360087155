body {
    margin: 1em;
}

h1.title {
    margin-bottom: -5px;
}

.version-label {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 8px;
    margin-left: 5px;
}

.truck-scheduler-app {
    margin-top: -20px;
}

.draggable {
    background-color: rgb(194, 194, 194);
    margin: 5px;
    padding: 10px;
    width: 4em;
    text-align: center;
}

.draggable.block, .draggable.yard {
    width: 5em;
}

.draggable:hover {
    background-color: rgba(224, 224, 151, 0.952);
    cursor: grab;
}

.drag-disabled {
    user-select: none;
}

.drag-disabled:hover {
    cursor: pointer;
}

.scheduler-canvas {
    position: relative;
    height: 100%;
}

.scheduler-canvas-body {
    position: relative;
    background-color: rgb(245, 245, 245);
    min-height: 580px;
    width: 2298px;
}

.scheduler-canvas-container .schedule-rows .operating-unit-name-label {
    position: relative;
    color: gray;
}

.location-draggable-section {
    display: flex;
    margin-left: 15px;
}

.location-draggable-list {
    display: flex;
    flex-wrap: wrap;
    width: 13em;
    height: 0px;
}

.bottom-panel {
    display: flex;
    position: sticky;
    left: 20px;
    width: 0px;
}

.dashboard {
    position: absolute;
    left: 1030px;
    width: 450px;
}

.dashboard-block .loader-operator-list {
    margin-top: 5px;
    margin-left: -20px;
}

.draggable-sections, .schedule-row, .location-section {
    display: flex;
}

.draggable-sections {
    margin-top: 10px;
}

.truck-draggable-list .draggable {
    margin-bottom: 10px;
}

.truck-draggable-list, .destination-draggable-list, .block-draggable-list, .yard-draggable-list {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
}

.truck-draggable-list {
    width: 13em;
}

.block-draggable-list {
    width: 30em;
}

.block-draggable-list {
    height: 15em;
}

.yard-draggable-list {
    width: 8em;
    flex-direction: column;
    flex-wrap: nowrap;
}

.schedule-row {
    margin-bottom: 15px;
    margin-top: 10px;
    font-size: 12px;
    position: relative;
}

.schedule-rows {
    padding-top: 1px;
}

.truck-section, .scenario-truck-item {
    position: relative; 
    margin-right: 5px;
    width: 115px;
    height: 33px;
    padding: 10px;
    background: #dce4f2;
    z-index: 1;
    box-sizing: border-box;
    cursor: pointer;
}

.add-truck-prompt {
    position: absolute;
    z-index: 5;
    margin-bottom: 15px;
    margin-right: 5px;
    height: 30px;
    width: 115px;
    background: #c2c2c2;
    padding-left: 45px;
    font-size: 24px;
    box-sizing: border-box;
}

.location-item {
    padding-top: 10px;
    padding-left: 2px;
    height: 33px;
    background: #ffe1b7;
    text-transform: capitalize;
    z-index: 2;
    box-sizing: border-box;
    position: relative;
}

.location-item.block:hover {
    cursor: pointer;
}

.location-item.dryland:hover {
    cursor: pointer;
}

.location-item.transit:hover {
    cursor: pointer;
}

.add-location-prompt {
    margin-left: 15px;
    font-size: 24px;
    width: 40px;
    padding-top: 2px;
    padding-left: 12px;
}

.time-markers {
    display: flex;
    margin-left: 120px;
    margin-bottom: 5px;
    width: 2500px;
}

.time-marker:nth-child(odd) {
    background: #dedede73;
}

.time-marker {
    width: 120px;
    padding: 5px;
    box-sizing: border-box;
}

.current-time-tracker {
    border-left: 2px solid #ff23009c;
    position: absolute;
    height: 100%;
    margin-left: 120px;
    z-index: 3;
}

.dryland {
    background: #cea3a3be;
}

.block {
    background: #bcd192d6;
}

.yard {
    background: #a1d6e2d6;
    text-transform: capitalize;
}

.hour-markers-container {
    margin-left: 120px;
}

.half-hour-marker {
    width: 60px;
    border-right: 0.5px solid #e4e4e4;
    position: absolute;
    height: 100%;
    z-index: 0;
}

.hour-marker {
    margin-left: 60px;
    width: 60px;
    border-right: 1px solid #cecece;
    position: absolute;
    height: 100%;
    z-index: 1;
}

.hour-marker-container:nth-child(odd) {
    position: absolute;
    width: 120px;
    height: 100%;
    background: #fefefed9;
}

.start-time-setter {
    margin-left: 1px;
    z-index: 1;
}

.begin-workday-marker, .end-workday-marker {
    border-left: 2px solid #d23434b8;
    height: 100%;
    position: absolute;
    z-index: 3;
}

.workday-marker-container {
    position: relative;
}

.schedule-background {
    background: #e8e8e800;
    width: 100%;
    border-top: 0.5px solid #dcdcdc;
    border-bottom:  0.5px solid #dcdcdc;
    position: absolute;
    height: 100%;
}

.scheduler-canvas-container {
    max-height: 580px;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 2298px;
}

.delete-item {
    display: none;
    position: absolute;
    background: #b5b5b594;
    width: 25px;
    padding-left: 7px;
    font-size: 14px;
    text-align: left;
    color: #414141;
    top: -12px;
    right: -5px;
    z-index: 2;
    cursor: pointer;
    box-sizing: border-box;
}

.location-item:hover > .delete-item, .scenario-truck-item:hover > .delete-item {
    display: block;
}

.block-id-picker {
    margin-bottom: 10px;
}

.block-load-rows {
    margin-left: -15px;
}

.load-order-line-break {
    border-bottom: 0.5px solid #cacaca;
    width: 415px;
}

.block-load-row
    .number-of-loads,
    .destination-picker,
    .delete-block-load-row {
    margin-right: 20px;
    margin-bottom: 10px;
}

.block-load-row .number-of-loads {
    width: 50px;
    margin-left: 25px;
}

.block-load-rows .assigned-value, .remaining-value  {
    box-sizing: border-box;
    display: inline-block;
    width: 45px;
    text-align: right;
}

.block-load-rows .remaining-value {
    margin-left: 40px;
}

.block-load-row .destination-picker {
    margin-left: 50px;
}

.block-load-edit-labels label {
    margin-right: 20px;
}

.block-load-edit-labels {
    margin-bottom: 10px;
}

.block-load-edit-labels .total-label {
    margin-right: 35px;
}

.block-load-edit-labels .order-label {
    margin-right: 30px;
}

.react-contextmenu {
    background: white;
    border: 0.5px solid #bbbbbb;
    z-index: 10;
}

.react-contextmenu-item {
    padding: 10px;
    border-bottom: 0.5px solid #bbbbbb;
}

.react-contextmenu-item:hover {
    background: #20a0ff;
    cursor: pointer;
    color: white;
}

.react-contextmenu-item--disabled {
    background: white;
    color: #cecece;
    cursor: default;
    border-right: 0.5px solid #bbbbbb;
}

.ReactModal__Overlay {
    z-index: 100;
}

.ReactModal__Content {
    width: 430px;
}

.context-menu-title {
    padding: 5px;
    font-size: 12px;
    border-bottom: 0.5px solid #e4e4e4;
    pointer-events: none;
}

.block-load-editor button.done {
    margin-left: 30px;
}

.block-load-editor .add-load {
    margin-left: 262px;
}

.block-load-editor .block-load-comment {
    resize: none;
    width: 408px;
}

.transit {
    background-color: #8194c5de;
    z-index: 2;
}

.scenario-date-picker {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    padding: 10px;
    padding-top: 0px;
    margin-top: 20px;
    display: flex;
}

.scenario-date-picker input {
    height: 18px;
    width: 150px;
    padding: 5px;
    font-size: 16px;
    font-weight: 400;
}

.scenario-date-picker .DayPickerInput-Overlay {
    z-index: 10;
}

.scenario-date-picker .DayPicker-TodayButton {
    margin-left: 110px;
}

.truck-scheduler-header {
    display: flex;
    margin-bottom: 5px;
}

.truck-scheduler-header .logo {
  width: 152px;
  height: 43.5px;
  margin-top: 24px;
  margin-left: -12px;
}

.scenario-edit-section {
    display: flex;
}

.scenario-picker {
    margin-top: 20px;
    height: 32px;
    width: 280px;
    font-size: 13px;
    padding: 5px;
}

.scenario-section
    .add-scenario-button,
    .add-scenario-done-button,
    .add-scenario-cancel-button,
    .scenario-settings-button,
    .rename-scenario-done-button,
    .rename-scenario-cancel-button {
    margin-top: 20px;
    height: 32px;
    font-size: 16px;
    margin-left: 10px;
}

.add-scenario-done-button, .rename-scenario-done-button {
    width: 55px;
    font-size: 16px;
}

.add-scenario-cancel-button, .rename-scenario-cancel-button {
    width: 65px;
    font-size: 16px;
}

.add-scenario-form, .rename-scenario-form {
    margin-top: 20px;
    height: 19px;
    padding: 5px;
    font-size: 16px;
    width: 255px;
}

.forward-date-arrow, .back-date-arrow {
    height: 32px;
    font-size: 22px;
    font-weight: bold;
}

.forward-date-arrow {
    margin-left: 10px;
}

.back-date-arrow {
    margin-right: 10px;
}

.scenario-truck-editor .trailer-picker-section, .driver-picker-section {
    display: flex;
    margin-bottom: 20px;
}

.trailer-picker-section .trailer-picker {
    height: 20px;
    margin-left: 13px;
}

.driver-picker-section .driver-picker {
    margin-left: 15px;
}

.scenario-truck-editor-buttons {
    margin-top: 10px;
    display: flex;
}

.scenario-truck-editor-cancel {
    margin-left: 250px;
}

.scenario-truck-editor-done {
    margin-left: 20px;
}

.scenario-truck-context-menu {
    z-index: 2;
}  

.scenario-truck-editor .comments-section {
    margin-top: 15px;
}

.scenario-truck-editor .comments-section textarea {
    resize: none;
}

.scenario-truck-editor .dispatcher-comments {
    margin-top: 15px;
}

.scenario-truck-editor .truck-picker-section {
    margin-bottom: 15px;
}

.scenario-truck-editor .truck-picker-section .truck-selection {
    margin-left: 20px;
}

.scenario-truck-context-menu .react-contextmenu {
    font-size: 16px;
}

.scenario-truck-context-menu .react-contextmenu--visible {
    z-index: 20;
}

.workday-duration-section {
    margin-bottom: 20px;
}

.workday-duration-section .workday-duration-input {
    margin-left: 15px;
    width: 60px;
}

.location-schedule-context-menu .react-contextmenu-item:not(.context-menu-title) {
    font-size: 16px;
}

.location-schedule-comment-modal .display-name {
    margin-bottom: 10px;
}

.location-schedule-comment-modal .property-labels,
  .delay-label,
  .comment-label,
  .dispatcher-comment-label {
    font-size: 14px;
}

.location-schedule-comment-modal .delay-section, .comment-section {
    margin-bottom: 10px;
}

.delay-section .load-time-delay-checker {
    margin-bottom: 15px;
    font-size: 14px;
}

.location-schedule-comment-modal .end-time {
    margin-bottom: 10px;
}

.location-schedule-comment-modal .comment-field {
    margin-bottom: 15px;
}

.location-schedule-comment-modal .cancel {
    margin-left: 250px;
}

.location-schedule-comment-modal .done {
    margin-left: 15px;
}

.location-schedule-comment-modal .loader-section {
    margin-bottom: 10px;
}

.location-delay {
    z-index: 2;
    box-sizing: border-box;
    outline: 3px solid #8194c5de;
    outline-offset: -3px;
}

.transit.location-delay {
    outline-color: #8194c5de;
    background-image: linear-gradient(45deg, #8194c5 8.33%, #ffffff 8.33%, #ffffff 50%, #8194c5 50%, #8194c5 58.33%, #ffffff 58.33%, #ffffff 100%);
    background-size: 16.97px 16.97px;
}

.block.location-delay {
    outline-color: #bcd192d6;

    background-image: linear-gradient(45deg, #bcd192d6 8.33%, #ffffff 8.33%, #ffffff 50%, #bcd192d6 50%, #bcd192d6 58.33%, #ffffff 58.33%, #ffffff 100%);
    background-size: 16.97px 16.97px;
}

.dryland.location-delay {
    outline-color: #cea3a3be;

    background-image: linear-gradient(45deg, #cea3a3be 8.33%, #ffffff 8.33%, #ffffff 50%, #cea3a3be 50%, #cea3a3be 58.33%, #ffffff 58.33%, #ffffff 100%);
    background-size: 16.97px 16.97px;
}

.yard.location-delay {
    outline-color: #a1d6e2d6;
    background-image: linear-gradient(45deg, #a1d6e2d6 8.33%, #ffffff 8.33%, #ffffff 50%, #a1d6e2d6 50%, #a1d6e2d6 58.33%, #ffffff 58.33%, #ffffff 100%);
    background-size: 16.97px 16.97px;
}

.schedule-scroll-top, .schedule-scroll-bottom {
    height: 2px;
    width: 2298px;
    background-color:  #7300e6;
    position: relative;
}

.schedule-scroll-top {
    margin-bottom: 5px;
}

.schedule-scroll-bottom {
    margin-bottom: 15px;
}

.schedule-scroll-bottom:hover, .schedule-scroll-top:hover {
    cursor: pointer;
}

.scroll-icon {
    transform:  scale(3, 1);
}

.schedule-scroll-bottom .scroll-bottom-button {
    margin-top: -5px;
}

.scroll-top-button, .scroll-bottom-button {
    border: none;
    background-color: #ffffff00; 
    color: #9b9b9b;
    font-size: 20px;
    height: 25px;
    width: 30px;
    margin-left: 70px;
    cursor: pointer;
}

.scenario-truck-delete-modal .delete-modal-buttons {
    margin-left: 220px;
}

.scenario-truck-delete-modal .delete-button {
    margin-left: 15px;
}

.scenario-truck-delete-modal .delete-share-button {
    margin-top: 15px;
    width: 127px;
}

.location-item-container {
    text-align: center;
    display: flex;
}

.dashboard-block-loads {
    position: absolute;
    top: 20px;
    left: 240px;
    height: 250px;
    overflow-y: scroll;
    border-bottom: 1px solid #dcdcdc;
    border-left: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
}

.dashboard-block-loads .headers {
    display: flex;
    margin-left: 20px;
    margin-top: 5px;
}

.dashboard-block-loads .unordered-label {
    margin-left: 20px;
}

.dashboard-block-loads .comment {
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 0.5px solid #bbbbbb;
    width: 350px;
}

.dashboard-block-loads .comment .comment-text {
    font-weight: bold;
}

.dashboard-block-loads .dashboard-block-load-row {
    margin-left: 15px;
}

.dashboard-block-load-row .assigned-value {
    margin-left: 30px;
}

.dashboard-block-loads .headers div {
    margin-right: 15px;
}

.dashboard-block-load-row {
    display: flex;
    margin-bottom: 10px;
}

.dashboard-block-load-row div {
    text-align: end;
    width: 35px;
}

.dashboard-block-load-row .total-value {
    margin-left: 20px;
}

.dashboard-block-load-row .assigned-value {
    margin-left: 30px;
}

.dashboard-block-load-row .remaining-value {
    margin-left: 40px;
}

.dashboard-block-load-row .name-value {
    margin-left: 65px;
}

/* .load-inventory-status {
    margin-left: 25px;
    margin-top: 20px;
} */

.inventory-status-and-count-container {
    display: flex;
    margin-left: 25px;
    margin-top: 20px;
}

.truck-count {
    margin-left: 25px;
    display: flex;
    /* margin-top: 20px; */
}

.truck-count-per-operatingUnit {
    margin-left: 25px;
}

.copy-scenario-modal .copy-cancel {
    margin-left: 130px;
}

.copy-scenario-modal .copy-done {
    margin-left: 15px;
}

.copy-scenario-modal .copy-chosen-date-label {
    margin-left: 20px;
}

.copy-prompt {
    margin-bottom: 20px;
}

.load-inventory-error {
    font-size: 20px;
}

.loader-picker-modal .loader-picker {
    margin-top: 15px;
}

.loader-picker-modal .loader-modal-buttons {
    margin-left: 200px;
}

.loader-modal-buttons .done-button {
    margin-left: 25px;
}

.loader-picker-modal .loader-picker {
    min-width: 130px;
}

.send-pdf-modal-buttons {
    margin-top: 20px;
    margin-left: 160px;
}

.send-pdf-modal-buttons .cancel-button {
    margin-right: 20px;
}

.user-settings-button, .view-selection-button {
    position: relative;
    font-size: 16px;
    top: 20px;
    height: 32px;
    margin-left: 10px;
}

.sort-schedule-button {
    position: absolute;
    margin-top: 5px;
}

.no-scenarios-available {
    margin-top: 15px;
    font-size: 28px;
}

.location-item-container .yard-name-hover {
    display: none;
}

.location-item.yard:hover {
    z-index: 3;
}

.location-item.yard:hover > .yard-name-hover {
    display: block;
    position: absolute;
    margin-left: 10px;
    width: 60px;
    background: #a1d6e2;
    padding: 10px;
    z-index: 3;
}

.operating-unit-selection-container .operating-unit-select-button {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 16px;
    height: 32px;
}

.operating-unit-filter-options-container .operating-unit-filter-row {
    margin-bottom: 10px;
}

.operating-unit-modal-buttons {
    float: right;
    margin-right: 30px;
}

.operating-unit-modal-buttons .done-button {
    margin-left: 20px;
}

.operating-unit-filter-options-container .select-all-button {
    margin-bottom: 10px;
}

.scenario-delete-modal-buttons {
    float: right;
    margin-right: 10px;
}

.scenario-delete-modal-buttons .delete-button {
    margin-left: 20px;
}

.operating-unit-label-container .operating-unit-name-label {
    margin-bottom: 0px;
}

.operating-unit-label-container .operating-unit-divider {
    position: relative;
    border-top: 0.5px solid #808080;
}

.publish-scenario-modal .publish-button {
    margin-left: 15px;
}

.publish-scenario-modal .button-container {
    margin-top: 20px;
    margin-left: 110px;
}


.scenario-truck-share-buttons {
    margin-top: 10px;
    display: flex;
}

.scenario-truck-share-cancel {
    margin-left: 250px;
}

.scenario-truck-share-done {
    margin-left: 20px;
}

.scenario-truck-share .comments-section {
    margin-top: 15px;
}

.scenario-truck-share .comments-section textarea {
    resize: none;
}

.scenario-truck-share .dispatcher-comments {
    margin-top: 15px;
}


.scenario-truck-share .operating-unit-picker-section {
    margin-bottom: 15px;
}

.operating-unit-picker-section .operating-unit-picker {
    margin-top: 15px;
    height: 20px;
    margin-left: 13px;
}

.driver-experience-picker {
    margin-left: 10px;
    width: 95px;
}

.ranking-modal-buttons .done-button {
    margin-top: 15px;
    margin-left: 290px;
    width: 75px;
}

.destination-max-capacity-modal-buttons .done-button {
    margin-top: 15px;
    margin-left: 290px;
    width: 75px;
}

.dashboard-yard .sleeper-load-label {
    margin-bottom: 5px;
}

.yard-sleeper-editor {
    margin-top: 10px;
}

.yard-sleeper-editor .destination-picker {
    margin-top: 5px;
}

.yard-sleeper-editor .destination-label {
    margin-right: 10px;
}

.assign-sleeper-modal .assign-sleeper-modal-buttons {
    float: right;
}

.assign-sleeper-modal .assign-sleeper-modal-buttons .cancel-button {
    margin-right: 15px;
}

.driver-block-no-history {
    border-top: 2px solid red !important;
}

.import-sleepers-modal-buttons {
    float: right;
    margin-top: 30px;
}

.import-sleepers-modal-buttons .done-button {
    margin-left: 10px;
}


.import-sleepers-modal .import-prompt {
    margin-bottom: 20px;
}

.sleeper-load-editor .labels {
    margin-bottom: 10px;
    margin-left: 30px;
}

.sleeper-load-editor .destination-label {
    margin-left: 75px;
}

.sleeper-load-editor .originBlock-label {
    margin-left: 118px;
}

.sleeper-load-editor .sleeper-load-row {
    margin-bottom: 5px;
}

.sleeper-load-editor .sleeper-load-buttons {
    margin-left: 230px;
  }
  
  .sleeper-load-editor .sleeper-load-buttons button {
    font-size: 16px;
  }
  
  .sleeper-load-editor .done {
    margin-left: 20px;
  }

.sleeper-load-row .truck-picker {
    width: 8em;
}

.sleeper-load-row .originBlock-picker {
    width: 8em;
    margin-left: 50px;
}

.sleeper-load-row .destination-picker {
    margin-left: 50px;
    width: 8em;
}

.sleeper-load-row .delete-sleeper-load-row {
    background-color: #e8e8e8;
    border-width: 0px; 
    cursor: pointer;
}

.sleeper-load-row .warning-sign {
    display: inline-block;
    width: 30px;
}

.swap-schedule-activity-modal .target-location-picker-section .location-picker 
.swap-schedule-activity-modal .target-location-picker-section .destination-for-block-picker { 
    margin-top: 10px;
    width: 100px;
}


.swap-schedule-activity-modal .swap-modal-buttons {
    margin-top: 90px;
    margin-left: 220px;
}

.swap-schedule-activity-modal .swap-modal-buttons .done-button {
    margin-left: 15px;
}


.swap-schedule-activity-modal .target-location-picker-section {
    display: flex;
    margin-top: 20px;
}

.swap-schedule-activity-modal .swap-modal-loader-section,
.swap-schedule-activity-modal .swap-modal-destination-for-block-section {
    margin-left: 35px;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  
.Selectable .DayPicker-Day {
    border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}
  
.Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}

.rollup-modal-buttons {
    float: right;
    margin-top: 15px;
}

.rollup-modal-done-button {
    margin-left: 15px;
}

.react-sanfona {
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 35px;
  }
  
  .react-sanfona-item-title {
    background-color: #fafafa;
    border-top: 1px solid #ccc;
    color: #333;
    padding: 5px;
  }
  
  .react-sanfona-item:first-child .react-sanfona-item-title {
    border-top: none;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }

  .navistream-window .header .title {
      display: inline;
      margin-left: 200px;
  }
  
  .navistream-window .header .refresh-button {
      height: 30px;
      width: 50px;
      float: right;
  }

  .reopen-scenario-modal-buttons {
    float: right;
    margin-top: 25px;
  }

  .reopen-scenario-modal-done-button {
    margin-left: 15px;
  }

  .block-third-party-check {
      margin-bottom: 15px;
      font-size: 14px;
  }
